import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import "../../../styles/RDStyles/custom.css"
import ODIN_ICON from "../../../images/icon_1.svg"
import VECTOR_ICON from "../../../images/Others/RDOtherSvg/flash_light.svg"
import CHAIN_ICON from "../../../images/Others/RDOtherSvg/Chain.png"
import RECTANGLE_ICON from "../../../images/Others/RDOtherSvg/Arhives_alt_light.svg"
import API_ICON from "../../../images/Others/RDOtherSvg/API.png"

const AnimatedSpan = styled.span`
  color: transparent;
  background: linear-gradient(75deg, #282828, #f7f7f7, #ffffff, #ffffff);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
`

const OdinImage = styled.img`
  width: 54px;
  height: 54px;

  @media screen and (max-width: 768px) {
    width: 28px;
    height: 28px;
  }
`

const Heading = styled.h3`
  font-size: 68px;
  line-height: 81.6px;
  width: 425px;
  font-weight: 600;
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 1) -10%,
    rgba(255, 255, 255, 1) 50%
  );
  -webkit-background-clip: text;
  background-clip: text;

  @media screen and (max-width: 768px) {
    font-size: 34px;
    line-height: 40.8px;
    width: 100%;
    margin-bottom: 20px;
  }
`

const PotentialsContainer = styled.div`
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: transparent;
  border-image: linear-gradient(
      180deg,
      rgba(40, 40, 40, 0.8519782913165266) 100%,
      rgba(255, 255, 255, 0) 0%
    )
    1;
`

function Potentials() {

  return (
    <section
      className="flex flex-col mx-auto py-24 md:py-40 text-left md:flex-row lg:max-w-full"
      id="potentials"
    >
      <div className="w-full py-12 grid grid-cols-1 md:grid-cols-2">
        <div className="mb-10">
          <Heading>
            {/* Why the AI world needs */}
            <span className="reveal-text">
              {"Why the AI world needs".split(" ").map((word, i) => (
                <span className="word" key={i}>
                  {word}{" "}
                </span>
              ))}
            </span>
            <br className="block lg:hidden" />
            <OdinImage
              src={ODIN_ICON}
              className="inline-block -mt-2 lg:ml-2 md:ml-0 md:-mt-3 lg:-mt-3"
              data-aos="fade-up"
              data-aos-duration="800"
            />
            {/* DIN */}
            <span className="reveal-text">
              {"DIN".split(" ").map((word, i) => (
                <span className="word" key={i}>
                  {word}{" "}
                </span>
              ))}
            </span>
          </Heading>
        </div>
        <div className="w-full grid grid-cols-2">
          <PotentialsContainer className="px-3 py-4 md:px-12 md:py-8">
            <div className="flex flex-col justify-between">
              <img src={VECTOR_ICON} height={24} width={24} alt="" />
              <h4 className="text-sm md:text-xl mt-2 mb-5 font-semibold">
                Decentralized Control
              </h4>
              <p className="text-xs md:text-sm opacity-40">
                ODIN ensures AI applications operate transparently,
                free from centralized entities, empowering consumers with trust and autonomy.
              </p>
            </div>
          </PotentialsContainer>
          <PotentialsContainer className="px-3 py-4 md:px-12 md:py-8">
            <div className="flex flex-col justify-between">
              <img src={API_ICON} height={24} width={24} alt="" />
              <h4 className="text-sm md:text-xl mt-2 mb-5 font-semibold">
                Data Ownership
              </h4>
              <p className="text-xs md:text-sm opacity-40">
                Users retain full ownership of their data, enabling
                secure and ethical AI interactions without compromising privacy.
              </p>
            </div>
          </PotentialsContainer>
          <PotentialsContainer className="px-3 py-4 md:px-12 md:py-8">
            <div className="flex flex-col justify-between">
              <img src={RECTANGLE_ICON} height={24} width={24} alt="" />
              <h4 className="text-sm md:text-xl mt-2 mb-5 font-semibold">
                AI Accessibility
              </h4>
              <p className="text-xs md:text-sm opacity-40">
                ODIN democratizes access to AI tools and services,
                making advanced technologies available directly to consumers.
              </p>
            </div>
          </PotentialsContainer>
          <PotentialsContainer className="px-3 py-4 md:px-12 md:py-8">
            <div className="flex flex-col justify-between">
              <img src={CHAIN_ICON} height={24} width={24} alt="" />
              <h4 className="text-sm md:text-xl mt-2 mb-5 font-semibold">
                Ecosystem Synergy
              </h4>
              <p className="text-xs md:text-sm opacity-40">
                By connecting AI developers, consumers, and decentralized
                infrastructures, ODIN fosters innovation in a fair, collaborative environment.
              </p>
            </div>
          </PotentialsContainer>
        </div>
      </div>
    </section>
  )
}

export default Potentials
