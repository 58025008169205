import React, { useEffect, useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { keyframes } from "styled-components"
import RIGHT_ARROW_ICON from "../../../images/Others/RDOtherSvg/right-arrow.svg"
import RIGHT_ARROW_HOVER_ICON from "../../../images/Others/RDOtherSvg/right-arrowhover.svg"
import ODIN_ICON from "../../../images/icon_1.svg"
import "../../../styles/RDStyles/custom.css"

const AnimatedSpan = styled.span`
  color: transparent;
  background: linear-gradient(75deg, #008046b2, #00ff8b);
  -webkit-background-clip: text;
  background-clip: text;
`
const MainHeading = styled.h1`
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 1) -10%,
    rgba(255, 255, 255, 1) 50%
  );
  -webkit-background-clip: text;
  background-clip: text;

  @media screen and (max-width: 464px) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`

const OdinImage = styled.img`
  // width: 52px;
  // height: 52px;
  // width: 128px;

  // @media screen and (max-width: 768px) {
  //   width: 34px;
  //   height: 34px;
  // }
`

const OdinImageSpace = styled.span`
  // // width: 52px;
  // // height: 52px;
  // width: 128px;
  // height: 128px;

  // @media screen and (max-width: 768px) {
  //   width: 34px;
  //   height: 34px;
  // }
`

const ScrollDown = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;
`

const blinkCursor = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Cursor = styled.span`
  width: 20px;
  height: auto;
  background: linear-gradient(75deg, #008046b2, #00ff8b);
  margin-left: 4px;
  animation: ${blinkCursor} 0.7s infinite;
`

const PATTERN_PATH = "../../images/patterns/odineyegsm.svg"

const textOne = [
  "Smarter Chains, Smarter Choices: AI-Powered Blockchain Innovation.",
  "AI Meets Blockchain: Transforming Data into Decentralized Intelligence.",
  "Decentralized Trust, Intelligent Insights: Revolutionizing the Future Together.",
  "Unlock AI’s Potential with Blockchain Security and Transparency.",
  "Empowering Decentralized Solutions with AI Precision.",
  "AI + Blockchain: Where Data Meets Trust.",
]

const textTwo = [
  "Secure, Transparent, Intelligent: The Future of Digital Systems.",
  "Blockchain Integrity, AI Intelligence: Seamless Synergy.",
  "Redefining Possibilities with AI-Driven Blockchain",
  "Redefining AI: The Impact of Direct Data.",
  "Effortless Automation, Immutable Security.",
  "Smart Contracts, Smarter Decisions: Powered by AI.",
]

const textThree = [
  "AI Secured by Blockchain: Efficiency Meets Trust.",
  "Intelligence Secured, Decentralization Perfected.",
  "AI Algorithms Protected by Blockchain’s Strength.",
  "Blockchain Reliability, AI Agility: Future-Ready Tech.",
  "Building a Smarter, Safer World with AI and Blockchain.",
]

export default function Hero({ loaderDone }) {
  const [animatedTextOne, setAnimatedTextOne] = useState("")
  const [animatedTextTwo, setAnimatedTextTwo] = useState("")
  const [animatedTextThree, setAnimatedTextThree] = useState("")
  const [isAddingOne, setIsAddingOne] = useState(false)
  const [isAddingTwo, setIsAddingTwo] = useState(false)
  const [isAddingThree, setIsAddingThree] = useState(false)

  // const textOne = "AI, Redefined: Dive into Pure Data Empowerment."
  // const textTwo = "Data Unbound: Seize Tomorrow with Unrivaled Insights."
  // const textThree = "Data Transformed: Real-World Assets, Real-World Impact."

  const delay = 250

  useEffect(() => {
    setTimeout(() => {
      animateText(textOne, setAnimatedTextOne, setIsAddingOne)
    }, 2000)

    setTimeout(() => {
      animateText(textTwo, setAnimatedTextTwo, setIsAddingTwo)
    }, 5000)

    setTimeout(() => {
      animateText(textThree, setAnimatedTextThree, setIsAddingThree)
    }, 7000)
  }, [])

  const animateText = (texts, setTextFunction, setIsAddingFunction) => {
    let currentIndex = 0
    let wordIndex = 0
    let adding = true
    setIsAddingFunction(true)
    let isPaused = false // Track if animation is paused
    let pauseDuration = 2000 // Duration to pause before removing text

    const interval = setInterval(() => {
      const text = texts[currentIndex]
      if (!isPaused) {
        if (adding) {
          // Adding word by word
          if (wordIndex < text.length) {
            setTextFunction(prevText => prevText + text[wordIndex])
            wordIndex++
          } else {
            // Finished adding current sentence, start removing
            adding = false
            isPaused = true
            setTimeout(() => {
              isPaused = false // Resume animation after pause
              adding = false // Start removing
            }, pauseDuration)
          }
        } else {
          // Removing word by word
          if (wordIndex > 0) {
            setTextFunction(prevText => prevText.slice(0, -1))
            wordIndex--
          } else {
            // Finished removing current sentence, move to next sentence
            adding = true
            currentIndex = Math.ceil((currentIndex + 1) % texts.length)
          }
        }
      }
    }, delay)

    return () => clearInterval(interval)
  }

  return (
    <>
      <section
        className="flex flex-col mx-auto pt-16 pb-16 text-left md:flex-row lg:max-w-full lg:pb-24"
        id="hero"
      >
        <div className="w-full z-10">
          <h1 className="text-left font-semibold subHeading sm:leading-heading py-5 text-xs md:text-base">
            <AnimatedSpan className="AnimatedSpan">
              {animatedTextOne}
            </AnimatedSpan>
            <Cursor>{isAddingOne && "|"}</Cursor>
          </h1>
          <h1 className="text-right md:me-48 font-semibold subHeading sm:leading-heading py-5 text-xs md:text-base">
            <AnimatedSpan className="AnimatedSpan">
              {animatedTextTwo}
            </AnimatedSpan>
            <Cursor>{isAddingTwo && "|"}</Cursor>
          </h1>
          <div className="lg:w-1/2 md:mx-auto text-center font-semibold py-20">
            <MainHeading
              className="text-6xl md:text-8xl xl:text-6xl"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              {/* Y
              <OdinImageSpace
                className={`inline-block -ml-2 mr-1 animate-span ${
                  loaderDone && "relative"
                }`}
              >
                <OdinImage
                  src={ODIN_ICON}
                  alt=""
                  className={`inline-block absolute ${
                    loaderDone && "odinAnimate"
                  }`}
                />
              </OdinImageSpace>
              ur data feeds AI */}
              AI pushes the boundaries of the future
            </MainHeading>
            <div className="pt-3">
              <span className="mt-3 text-textSecondary md:my-2 opacity-40 font-medium text-base md:text-xl">
                Embrace ODIN to unlock the potential of an AI-driven web3-based world.
              </span>
              <a
                href="https://scan.odinprotocol.io/validators"
                target="_blank"
                rel="noopener noreferrer"
                className="border startBtn py-2 px-6 mt-8 text-xs uppercase mx-auto text-black bg-[yellow] flex items-center justify-between hover:text-[yellow] hover:border-[yellow] hover:bg-black "
                style={{ borderRadius: "53px", height: "41px", width: "150px" }}
              >
                Start Now
                <img
                  src={RIGHT_ARROW_ICON}
                  className="h-5 w-5 ms-3 icon"
                  alt=""
                />
                <img
                  src={RIGHT_ARROW_HOVER_ICON}
                  className="h-5 w-5 ms-3 hoverIcon"
                  alt=""
                />
              </a>
            </div>
          </div>
          <h1 className="w-1/2 mx-auto text-left font-semibold subHeading sm:leading-heading py-5 text-xs md:text-base">
            <AnimatedSpan className="AnimatedSpan">
              {animatedTextThree}
            </AnimatedSpan>
            <Cursor>{isAddingThree && "|"}</Cursor>
          </h1>
          <div className="text-center mt-20 hidden lg:block">
            <ScrollDown href="#staking-odin">Scroll Down</ScrollDown>
          </div>
        </div>
      </section>
    </>
  )
}
