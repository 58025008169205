import React from "react"
import logoSvg from "../../../images/logo.svg"
import signatureSvg from "../../../images/social/ThinkSebSmiley.svg"
import signatureSvgHover from "../../../images/social/RDSvg/ThinkSebSmileyhover.svg"
import HoverImage from "react-hover-image"
import SocialLinksFooter from "../elementsRD/SocialLinksFooter"

const desktopLinks = [
  { name: "ecosystem", href: "#ecosystem" },
  { name: "roadmap", href: "#roadmap" },
  {
    name: "Docs",
    href: "https://docs.odinprotocol.io",
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    name: "forum",
    href: "https://forum.odinprotocol.io/",
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    name: "runa",
    href: "https://runa.odinprotocol.io/",
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    name: "sight",
    href: "https://sight.odinprotocol.io/",
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    name: "stake",
    href: "https://scan.odinprotocol.io/validators",
    target: "_blank",
    rel: "noopener noreferrer",
  },
]

const mobileLinks = [
  { name: "ecosystem", href: "#ecosystem" },
  { name: "roadmap", href: "#roadmap" },
  {
    name: "Docs",
    href: "https://docs.odinprotocol.io",
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    name: "forum",
    href: "https://forum.odinprotocol.io/",
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    name: "runa",
    href: "https://runa.odinprotocol.io/",
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    name: "stake",
    href: "https://scan.odinprotocol.io/validators",
    target: "_blank",
    rel: "noopener noreferrer",
  },
]

export default function Footer() {
  const renderLinks = links => (
    <nav className="flex flex-col me-10 items-baseline">
      {links.map(tab => (
        <a
          key={tab.name}
          href={tab.href}
          target={tab.target || ""}
          rel={tab.rel || ""}
          className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 my-2 opacity-80 text-textPrimary border border-textPrimary text-base font-customExtraBold hover:bg-[yellow] hover:text-black hover:border-[yellow]"
          style={{ borderRadius: "44px", height: "26px" }}
        >
          {tab.name}
        </a>
      ))}
    </nav>
  )

  return (
    <footer>
      <div className="hidden lg:block rounded-xl my-4 pt-20 p-8 bg-[#242424]">
        <div className="flex justify-between gap-4 mb-10 pl-6">
          <div className="w-2/4">
            <img
              className="my-2"
              height={44}
              width={150}
              src={logoSvg}
              alt="ODIN"
            />
          </div>
          <div className="w-2/4 flex">
            <div className="flex w-2/4 justify-around">
              {renderLinks(desktopLinks.slice(0, 3))}
              {renderLinks(desktopLinks.slice(3))}
            </div>
            <div className="w-2/4">
              <div className="flex w-full justify-center mt-3 mb-5 lg:justify-end">
                <SocialLinksFooter />
              </div>
              {/* <a
                href="www.thinkseb.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex w-full justify-center lg:justify-end"
              >
                <img
                  className="mt-2 opacity-80 hover:opacity-100"
                  src={signatureSvg}
                  height={45}
                  width={68}
                />
              </a> */}
            </div>
          </div>
        </div>
        <div className="px-6 pt-10">
          <p className="text-sm text-textPrimary">
            Copyright © {new Date().getFullYear()}. All rights reserved. GeoDB
            Foundation Ltd.
          </p>
        </div>
      </div>

      <div className="lg:hidden rounded-xl my-4 px-3 pt-6 pb-4 bg-[#242424]">
        <div className="mb-16">
          <div className="w-full">
            <img
              className="my-2"
              height={25}
              width={85}
              src={logoSvg}
              alt="ODIN"
            />
          </div>
          <div className="flex">
            <div className="w-2/4">{renderLinks(mobileLinks.slice(0, 2))}</div>
            <div className="w-2/4">
              <div className="flex w-full justify-end mt-3 mb-6 lg:justify-end">
                <SocialLinksFooter />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div>{renderLinks(mobileLinks.slice(2))}</div>
          <div className="flex items-baseline justify-between mt-3">
            {/* <div> */}
            <p
              className="text-textPrimary opacity-40"
              style={{ fontSize: "8px" }}
            >
              Copyright © {new Date().getFullYear()}. All rights reserved. GeoDB
              Foundation Ltd.
            </p>
            {/* </div> */}
            {/* <a
              href="www.thinkseb.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="opacity-80 hover:opacity-100"
                src={signatureSvg}
                height={45}
                width={68}
              />
            </a> */}
          </div>
        </div>
      </div>
    </footer>
  )
}
