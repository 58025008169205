import React from "react"
import styled from "styled-components"
import RIGHT_ARROW_ICON from "../../../images/Others/RDOtherSvg/right-arrow.svg"
import RIGHT_ARROW_HOVER_ICON from "../../../images/Others/RDOtherSvg/right-arrowhover.svg"
import ODIN_ICON from "../../../images/icon.png"


const Container = styled.section`
  height: 510px;
  border-radius: 12px;
  width: 100%;
  text-align: center;
  background-color: #ffe500;

  @media screen and (max-width: 768px) {
    height: 620px;
  }
`

const Heading = styled.h1`
  font-size: 92px;
  font-weight: 600;
  color: #000;

  @media screen and (max-width: 768px) {
    font-size: 60px;
  }
`

const OdinImage = styled.img`
  width: 72px;
  height: 72px;
  top: 0;
  left: 0;

  @media screen and (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`

function Cta() {
  return (
    <Container>
      <div className="md:w-3/4 mx-auto relative top-2/4 -translate-y-2/4">
        <Heading>
          <OdinImage
            src={ODIN_ICON}
            className="inline-block -mt-2 md:-mt-4"
            data-aos="fade-up"
            data-aos-duration="800"
          />
          DIN feeds AI</Heading>
        <p className="opacity-40 text-black mx-1">
          Embrace ODIN to unlock the AI-driven future.
        </p>
        <a
          href="https://scan.odinprotocol.io/validators"
          target="_blank"
          rel="noopener noreferrer"
          className="border startBtn py-3 px-6 mt-8 text-xs uppercase mx-auto text-[#FFE500] bg-black flex items-center justify-between hover:text-black hover:border-black hover:bg-[#FFE500]"
          style={{
            borderRadius: "53px",
            height: "41px",
            width: "150px",
            boxShadow: "0px 15px 34px -4px #0000009C",
          }}
        >
          Start Now
          <img
            src={RIGHT_ARROW_HOVER_ICON}
            className="h-5 w-5 ms-3 icon"
            alt=""
          />
          <img
            src={RIGHT_ARROW_ICON}
            className="h-5 w-5 ms-3 hoverIcon"
            alt=""
          />
        </a>
      </div>
    </Container>
  )
}

export default Cta
