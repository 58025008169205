import React, { useEffect } from "react"
import styled from "styled-components"

const Heading = styled.h1`
  font-size: 68px;
  line-height: 81.6px;
  font-weight: 600;
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 1) -10%,
    rgba(255, 255, 255, 1) 50%
  );
  -webkit-background-clip: text;
  background-clip: text;

  @media screen and (max-width: 768px) {
    font-size: 34px;
    line-height: 40.8px;
    width: 100%;
  }
`

const StakeCard = styled.div`
  width: 340px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 22px;
    padding: 2px;
    background: linear-gradient(
      180deg,
      rgba(40, 40, 40, 0.8519782913165266) 45%,
      rgba(255, 255, 255, 0) 60%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @media screen and (max-width: 768px) {
    width: 220px;
  }
`

const StakeP = styled.p`
  font-size: 14px;
  color: #01eb81;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`

const StakeSpan = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: #fff;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 16.8px;
  }
`

function Roadmap() {
  return (
    <section className="py-20 md:py-40" id="roadmap">
      <div>
        <div className="mb-8 flex justify-center">
          <Heading>
            {/* Roadmap */}
            <span className="reveal-text">
              {"Roadmap".split(" ").map((word, i) => (
                <span className="word" key={i}>
                  {word}{" "}
                </span>
              ))}
            </span>
          </Heading>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 overflow-x-scroll mb-8">
          {/* <StakeCard
            className="p-4 md:py-12 md:px-8"
            style={{ width: "340px !important", position: "static" }}
          ></StakeCard> */}
          <StakeCard className="w-full p-4 md:pt-12 md:pb-0 md:px-8">
            <div
              className="flex flex-col justify-between"
              style={{ minHeight: "200px" }}
            >
              <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-3">
                  SKÖPUN: AI Generative Art and NFTs dapp
                </StakeSpan>
                <StakeP className="opacity-80">[2024]</StakeP>
              </div>
              {/* <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-3">Native tokens DeX</StakeSpan>
                <StakeP className="opacity-80">[2024]</StakeP>
              </div>
              <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-3">
                  Privacy-preserving solution V1
                </StakeSpan>
                <StakeP className="opacity-80">[2024]</StakeP>
              </div> */}
            </div>
          </StakeCard>

          <StakeCard className="w-full p-4 md:pt-12 md:pb-0 md:px-8">
            <div
              className="flex flex-col justify-between"
              style={{ minHeight: "200px" }}
            >
              <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-3">OdinGPT v1.0: Your strongest crypto companion</StakeSpan>
                <StakeP className="opacity-80">[2024]</StakeP>
              </div>
              {/* <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-3">
                  Data management platform integration
                </StakeSpan>
                <StakeP className="opacity-80">[2024]</StakeP>
              </div>
              <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-3">Play-to-earn SDK</StakeSpan>
                <StakeP className="opacity-80">[2024]</StakeP>
              </div> */}
            </div>
          </StakeCard>

          <StakeCard className="w-full p-4 md:pt-12 md:pb-0 md:px-8">
            <div
              className="flex flex-col justify-between"
              style={{ minHeight: "200px" }}
            >
              <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-6">
                  SKÖPUN v2.0: Create Art, mint NFTs, trade and exchange
                </StakeSpan>
                <StakeP className="opacity-80" style={{ color: "#FF92AF" }}>
                  [2025]
                </StakeP>
              </div>
              {/* <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-3">
                  Developers incentive program to onboard new dapps developers
                </StakeSpan>
                <StakeP className="opacity-80">[2024]</StakeP>
              </div> */}
            </div>
          </StakeCard>

          <StakeCard className="w-full p-4 md:pt-12 md:pb-0 md:px-8">
            <div
              className="flex flex-col justify-between"
              style={{ minHeight: "200px" }}
            >
              <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-3">TRIPR: The most powerful Travel Guide</StakeSpan>
                <StakeP className="opacity-80" style={{ color: "#FF92AF" }}>
                  [2025]
                </StakeP>
              </div>
              {/* <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-3">Data DeX</StakeSpan>
                <StakeP className="opacity-80" style={{ color: "#FF92AF" }}>
                  [2025]
                </StakeP>
              </div>
              <div className="flex justify-between md:w-auto">
                <StakeSpan className="mr-3">ODIN Dapp Store</StakeSpan>
                <StakeP className="opacity-80" style={{ color: "#FF92AF" }}>
                  [2025]
                </StakeP>
              </div> */}
            </div>
          </StakeCard>
        </div>
      </div>
    </section>
  )
}

export default Roadmap

