import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import ODIN_ICON from "../../../images/icon_1.svg"
import "../../../styles/RDStyles/custom.css"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const OdinImage = styled.img`
  width: 37px;
  height: 37px;

  @media screen and (max-width: 768px) {
    width: 17px;
    height: 17px;
  }
`

const Heading = styled.h3`
  font-size: 44px;
  line-height: 57.2px;
  width: 710px;
  font-weight: 600;
  color: transparent;
  // background: linear-gradient(
  //   90deg,
  //   rgba(40, 40, 40, 1) -10%,
  //   rgba(255, 255, 255, 1) 50%
  // );
  background: linear-gradient(270deg, #ffffff 31.56%, #282828 141.41%);
  -webkit-background-clip: text;
  background-clip: text;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
    width: 100%;
    margin-bottom: 20px;
  }
`

const Paragraph = styled.p`
  font-size: 22px;
  line-height: 30.8px;
  width: 487px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 19.6px;
    width: 100%;
  }
`

function About({ isSplitting }) {
  // const odinImageRefAbout = useRef(null)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const splitWords = selector => {
      const elements = document.querySelectorAll(selector)

      elements.forEach(function (el) {
        el.dataset.splitText = el.textContent
        el.innerHTML = el.textContent
          .split(/\s/)
          .map(function (word) {
            return word
              .split("-")
              .map(function (word) {
                return '<span class="word ml-1">' + word + "</span>"
              })
              .join('<span class="hyphen">-</span>')
          })
          .join('<span class="whitespace"> </span>')
      })
    }

    const getLines = el => {
      var lines = []
      var line
      var words = el.querySelectorAll("span")
      var lastTop
      for (var i = 0; i < words.length; i++) {
        var word = words[i]
        if (word.offsetTop != lastTop) {
          if (!word.classList.contains("whitespace")) {
            lastTop = word.offsetTop

            line = []
            lines.push(line)
          }
        }
        line.push(word)
      }
      return lines
    }

    const splitLines = selector => {
      const elements = document.querySelectorAll(selector)
      splitWords(selector)

      elements.forEach(function (el) {
        const lines = getLines(el)
        const wrappedLines = lines
          .map(function (wordsArr) {
            return (
              '<span class="line"><span class="words custom-gradient-text">' +
              wordsArr.map(word => word.outerHTML).join("") +
              "</span></span>"
            )
          })
          .join("")
        el.innerHTML = wrappedLines
      })
    }

    splitLines(".reveal-text")

    const revealText = document.querySelectorAll(".reveal-text")

    revealText.forEach(element => {
      const lines = element.querySelectorAll(".words")

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          toggleActions: "restart none none reset",
        },
      })
      tl.set(element, { autoAlpha: 1 })
      tl.from(lines, 1, {
        yPercent: 100,
        ease: "power3.out",
        stagger: 0.25,
        delay: 0.2,
      })
    })

    ScrollTrigger.refresh()
  }, [])

  return (
    <>
      <section
        className="flex flex-col mx-auto py-20 md:py-40 text-left md:flex-row lg:max-w-full"
        id="about"
      >
        <div className="w-full md:flex justify-between">
          <Heading>
            <OdinImage
              src={ODIN_ICON}
              alt="ODIN Icon"
              className="inline-block -mt-1 lg:-mt-2"
              data-aos="fade-up"
              data-aos-duration="800"
            />
            <span className={isSplitting && "reveal-text"}>
              {"DIN is revolutionizing the future of data and artificial intelligence by enabling the creation of decentralized AI-powered dApps and pioneering the first Artificial Intelligence-to-Consumer (AI2C) decentralized ecosystem."
                .split(" ")
                .map((word, i) => (
                  <span className="word" key={i}>
                    {word}{" "}
                  </span>
                ))}
            </span>
          </Heading>

          <Paragraph className="text-[#5e5e5e] my-auto text-2xl leading-9">
            By harnessing the power of <span className="text-[#fff]">blockchain</span>, ODIN empowers <span className="text-[#fff]">developers</span> and <span className="text-[#fff]">businesses</span> to build <span className="text-[#fff]">AI solutions</span> that <span className="text-[#fff]">operate transparently</span>, <span className="text-[#fff]">securely</span>, and <span className="text-[#fff]">autonomously</span>, reshaping how consumers interact with intelligent systems in a <span className="text-[#fff]">decentralized world</span>.
          </Paragraph>
        </div>
      </section>
    </>
  )
}

export default About
