import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import ODIN_ICON from "../../../images/icon_1.svg"

const MainHeading = styled.h1`
  font-size: 68px;
  font-weight: 600;
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 1) -10%,
    rgba(255, 255, 255, 1) 50%
  );
  -webkit-background-clip: text;
  background-clip: text;

  @media screen and (max-width: 768px) {
    font-size: 33px;
  }
`

const OdinImage = styled.img`
  width: 54px;
  height: 54px;
  top: 0;
  left: 0;

  @media screen and (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`

const StakeCard = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 22px;
    padding: 2px;
    background: linear-gradient(
      180deg,
      rgba(40, 40, 40, 0.8519782913165266) 45%,
      rgba(255, 255, 255, 0) 60%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

const Heading = styled.h3`
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  color: #01eb81;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 14px;
  }
`

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 20px;

  @media screen and (max-width: 768px) {
    font-size: 11px;
    line-height: 16px;
  }
`

function CoreFunctionality() {

  return (
    <section className="py-24 md:py-40" id="core-functionality">
      <div>
        <div className="mb-16 flex justify-center">
          <MainHeading>
            <OdinImage
              src={ODIN_ICON}
              className="inline-block -mt-2 md:-mt-4"
              data-aos="fade-up"
              data-aos-duration="800"
            />
            {/* DIN core functionality */}
            <span className="reveal-text">
              {"DIN core functionality".split(" ").map((word, i) => (
                <span className="word" key={i}>
                  {word}{" "}
                </span>
              ))}
            </span>
          </MainHeading>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-4">
          <StakeCard className="rounded-3xl px-3 py-6 lg:px-8 lg:py-12">
            <Heading>L1 Blockchain protocol</Heading>
            <Paragraph className="opacity-40">
              L1 Blockchain protocol focused on
              <br className="md:block" /> the development of AI Capabilities
            </Paragraph>
          </StakeCard>
          <StakeCard className="rounded-3xl px-3 py-6 lg:px-8 lg:py-12">
            <Heading>Data Ownership and Privacy</Heading>
            <Paragraph className="opacity-40">
              Empowers consumers to retain full control over their data, enabling secure interactions with
              AI applications while maintaining privacy and ethical standards.
            </Paragraph>
          </StakeCard>
          <StakeCard className="rounded-3xl px-3 py-6 lg:px-8 lg:py-12">
            <Heading>Direct AI-to-Consumer Interaction</Heading>
            <Paragraph className="opacity-40">
              Facilitates seamless connections between AI solutions and end-users, removing intermediaries
              to provide transparent and efficient access to AI-driven services.
            </Paragraph>
          </StakeCard>
          <StakeCard className="rounded-3xl px-3 py-6 lg:px-8 lg:py-12">
            <Heading>Trustless AI Governance</Heading>
            <Paragraph className="opacity-40">
              Implements decentralized governance mechanisms to ensure ethical AI development and usage,
              with stakeholders having a voice in decision-making.
            </Paragraph>
          </StakeCard>
          <StakeCard className="rounded-3xl px-3 py-6 lg:px-8 lg:py-12">
            <Heading>Interoperable AI Solutions</Heading>
            <Paragraph className="opacity-40">
              Provides a platform for integrating diverse AI tools and systems, enabling seamless
              collaboration and compatibility across different decentralized applications and networks.
            </Paragraph>
          </StakeCard>
          <StakeCard className="rounded-3xl px-3 py-6 lg:px-8 lg:py-12">
            <Heading>AI applications</Heading>
            <Paragraph className="opacity-40">
              Enables developers to create and deploy AI-powered dApps on a blockchain infrastructure,
              ensuring transparency, security, and autonomy.
            </Paragraph>
          </StakeCard>
        </div>
      </div>
    </section>
  )
}

export default CoreFunctionality
