import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import ODIN_ICON from "../../../images/icon_1.svg"
import COINMARKETCAP_ICON from "../../../images/Others/RDOtherSvg/coinmarketcap.png"
import KEPLR_ICON from "../../../images/Others/RDOtherSvg/keplr.png"
import KUJIRA_ICON from "../../../images/Others/RDOtherSvg/kujira.png"
import WALLACE_ICON from "../../../images/Others/RDOtherSvg/wallace.png"
import OSMOSIS_ICON from "../../../images/Others/RDOtherSvg/osmosis.png"
import ODIN_LONG_IMAGE from "../../../images/Others/RDOtherSvg/odin.png"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "../../../styles/RDStyles/animation.css"

gsap.registerPlugin(ScrollTrigger)

const Icons = [
  // { icon: WALLACE_ICON, url: "https://wallacewallet.io/" },
  { icon: ODIN_LONG_IMAGE, url: "https://runa.odinprotocol.io/" },
  { icon: OSMOSIS_ICON, url: "https://app.osmosis.zone/?from=OSMO&to=ODIN" },
  {
    icon: KUJIRA_ICON,
    url: "https://fin.kujira.network/trade/kujira1ky0v9x7rnuht2j327rlrhkcs37capvrag48qmgs5uxg0356h89kqgn55rp?q=all&s=odin",
  },
  {
    icon: COINMARKETCAP_ICON,
    url: "https://coinmarketcap.com/currencies/odin-protocol/",
  },
  { icon: KEPLR_ICON, url: "https://www.keplr.app/" },
]

const OdinImage = styled.img`
  width: 53px;
  height: 53px;

  @media screen and (max-width: 768px) {
    width: 28px;
    height: 28px;
  }
`

const MainHeading = styled.h1`
  font-size: 68px;
  line-height: 81.6px;
  font-weight: 600;
  margin: 0 auto;
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 1) -10%,
    rgba(255, 255, 255, 1) 50%
  );
  -webkit-background-clip: text;
  background-clip: text;

  @media screen and (max-width: 768px) {
    font-size: 34px;
    line-height: 40.8px;
    width: 100%;
    margin-bottom: 20px;
  }
`

const StakeCard = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 22px;
    padding: 2px;
    background: linear-gradient(
      180deg,
      rgba(40, 40, 40, 0.8519782913165266) 45%,
      rgba(255, 255, 255, 0) 60%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

const Heading = styled.h3`
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 14px;
  }
`

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 20px;

  @media screen and (max-width: 768px) {
    font-size: 11px;
    line-height: 16px;
  }
`

const Backer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 80%;
    max-height: 80%;
    mix-blend-mode: luminosity;
  }

  width: 300px;
  height: 48px;
`

const HorizontalScroll = styled.div`
  position: absolute;
  scrollbar-width: none;
  // display: block;
  top: 0;
  left: 0;
  // max-height: 100vw;
  max-height: 1300px;
  // max-height: 1250px;
  // max-height: 2000px;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transform: rotate(-90deg) translateY(-250px);
  transform-origin: right top;
  & > div {
    // display: block;
    padding: 5px;
    transform: rotate(90deg);
    transform-origin: right top;
  }

  @media screen and (max-width: 768px) {
    max-height: 100vw;
  }
`

const ecosystem = [
  {
    heading: "L1 Blockchain protocol",
    desc: "Explore diverse software solutions in the Odin ecosystem, from productivity tools to entertainment platforms.",
  },
  {
    heading: "AI dapps",
    desc: "Discover financial hubs for seamless trading of cryptocurrencies and digital assets within the Odin ecosystem.",
  },
  {
    heading: "Developer Environment",
    desc: " Unlock essential tools and services driving efficiency and functionality in the Odin ecosystem, from decentralized storage to blockchain-based identity management.",
  },
]

function Ecosystem() {
  const sectionsRef = useRef([])

  useEffect(() => {
    const sections = sectionsRef.current
    let totalWidth = 0
    const getMaxWidth = () => {
      sections.forEach(section => {
        totalWidth += section.offsetWidth
      })
    }

    getMaxWidth()
    ScrollTrigger.addEventListener("refreshInit", getMaxWidth)

    gsap.to(sections, {
      x: () => `-${totalWidth - window.innerWidth}`,
      ease: "none",
      scrollTrigger: {
        trigger: ".Wrapper",
        pin: true,
        scrub: 1,
        end: () => `+=${totalWidth}`,
        invalidateOnRefresh: true,
      },
    })

    return () => {
      ScrollTrigger.removeEventListener("refreshInit", getMaxWidth)
    }
  }, [])

  return (
    <section className="pt-20 md:py-40" id="ecosystem">
      <div>
        <div className="md:mb-16 flex">
          <MainHeading>
            <OdinImage
              src={ODIN_ICON}
              className="inline-block -mt-2 md:-mt-4"
              data-aos="fade-up"
              data-aos-duration="800"
            />
            <span className="reveal-text">
              {"DIN ecosystem".split(" ").map((word, i) => (
                <span className="word" key={i}>
                  {word}{" "}
                </span>
              ))}
            </span>
          </MainHeading>
        </div>
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-4">
            {ecosystem.map((item, ind) => (
              <StakeCard
                className="rounded-3xl px-3 py-6 lg:px-8 lg:py-12 text-center"
                key={ind}
              >
                <Heading>{item.heading}</Heading>
                {/* <Paragraph className="opacity-40">{item.desc}</Paragraph> */}
              </StakeCard>
            ))}
          </div>

          {/* SCROLLBAR IMAGES */}
          <div
            className="my-10 relative overflow-hidden"
            style={{ height: "300px" }}
          >
            <HorizontalScroll className="horizontal-scroll-squares">
              {Icons?.map((icon, ind) => (
                <div key={ind} className="flex justify-center items-center">
                  <Backer
                    // key={ind}
                    href={icon.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="grayscale my-3"
                  >
                    <img src={icon.icon} alt="icon" />
                  </Backer>
                </div>
              ))}
            </HorizontalScroll>
          </div>

          {/* <div className="Wrapper flex justify-evenly gap-4 mt-12 md:mt-16 overflow-hidden">
            <div
              className="flex items-center justify-start w-fit scroll-smooth h-screen"
              // If in future more images came remove this paddingStart. and also remove paddingBottom css in custom.css file
              // style={{ paddingInlineStart: "50rem" }}
            >
              {Icons?.map((item, ind) => (
                <Backer
                  key={ind}
                  ref={el => (sectionsRef.current[ind] = el)}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="grayscale my-3"
                >
                  <img src={item.icon} alt="" />
                </Backer>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default Ecosystem
